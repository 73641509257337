/* !
 *     __ _____ _____ ____  _____ _____        ___
 *  __|  |   | |   __|    \| __  | __  |   _ _| | |
 * |  |  | | | |__   |  |  | __ -|    -|  | | |_  |
 * |_____|_|___|_____|____/|_____|__|__|   \_/  |_|
 *
 * JNSDBR V4
 * Portfolio of Jens de Boer
 *
 * If you got a job to offer or you're interested to work with me,
 * feel free to drop me a line: hello@jnsdbr.de
 *
 * @version 4.2
 * @author Jens de Boer <jens@jnsdbr.de>
 */

const CONSOLESTYLE = [
    'color: #00FA9A',
    'text-transform: uppercase',
    'font-size: 1em',
    'display: block',
].join(';');

/**
 * JNSDBR V4
 */
class JNSDBR {
    /**
     * Constructor
     */
    constructor() {
        this.version = 4.2;
        console.log('%cJNSDBR V' + this.version, CONSOLESTYLE);

        this.btnToTop = document.querySelector('.totop');
        this.btnToTop.addEventListener('click',
            this.btnToTopListener.bind(this));

        // LQIP for project overview
        const imgDefer = document.querySelectorAll('img');
        imgDefer.forEach((image) => {
            if (image.getAttribute('data-src')) {
                image.setAttribute('src', image.getAttribute('data-src'));
            }
        });

        // Hide elements
        this.hiddenElements = [];
        this.hideElements();

        // Show article
        const article = document.querySelector('article');
        // CSS transition-delay not working properly "hack"
        setTimeout(() => article.classList.remove('hidden'), 100);

        // Show projectMenu
        const projectMenu = document.querySelector('#project-menu');
        if (projectMenu !== null) {
            setTimeout(() => projectMenu.classList.remove('hidden'), 100);
        }

        // Add listeners to all hrefs with data-anmiation="fade"
        this.addHrefListeners('a[data-animation="fade"]');

        // Scroll listener
        window.addEventListener('scroll', this.scrollListener.bind(this));
    }

    /**
     * Adds listener to hrefs
     * @param {string} selector
     */
    addHrefListeners(selector) {
        const hrefs = document.querySelectorAll(selector);
        hrefs.forEach((href) => {
            href.addEventListener('click', this.hrefListener.bind(this));
        });
    }

    /**
     * The listener for all hrefs
     * @param {object} event
     */
    hrefListener(event) {
        event.preventDefault();
        this.fadeOutTo(event.currentTarget)
            .then((data) => {
                window.location = data;
            });
    }

    /**
     * Fades the content out
     * @param {object} target
     */
    async fadeOutTo(target) {
        // Defaulting to article
        const article = document.querySelectorAll('article')[0];
        article.classList.add('hidden');

        // Check if project menu is present
        const projectMenu = document.querySelector('#project-menu');
        if (projectMenu != null) {
            if (target.title === 'Home' ||
                target.title === 'Projects' ||
                target.title === 'About') {
                projectMenu.classList.add('hidden');
            }
        }

        return await new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(target.href);
            }, 500);
        });
    }

    /**
     * Hides elements
     */
    hideElements() {
        const projects = document.querySelector('#projects ul');
        const project = document.querySelector('.project .content .media');
        let parent = null;

        if (projects != null) {
            parent = projects;
        }

        if (project != null) {
            parent = project;
        }

        if (parent != null) {
            for (const element of parent.children) {
                if (!this.isElementInViewport(element)) {
                    element.classList.add('hidden');
                    this.hiddenElements.push(element);
                }
            }
        }
    }

    /**
     * Checks if an element is inside the viewport
     * @param {*} el
     * @return {boolean}
     */
    isElementInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (rect.bottom >= 0 &&
            rect.right >= 0 &&
            rect.top <= (window.innerHeight ||
            document.documentElement.clientHeight) &&
            rect.left <= (window.innerWidth ||
            document.documentElement.clientWidth));
    }

    /**
     * Scroll listener
     * @param {*} event
     */
    scrollListener(event) {
        // Changed project-menu position
        const projectMenu = document.querySelector('#project-menu');
        if (projectMenu !== null) {
            if (window.scrollY > 50 && window.innerWidth >= 1024) {
                projectMenu.classList.add('fixed');
            } else {
                projectMenu.classList.remove('fixed');
            }
        }

        if (this.hiddenElements.length > 0) {
            for (let i = 0; i < this.hiddenElements.length; i++) {
                if (this.isElementInViewport(this.hiddenElements[i])) {
                    this.hiddenElements[i].classList.remove('hidden');
                    this.hiddenElements.splice(i, 1);
                }
            }
        }
    }

    /**
     * Scroll to top animation
     * @param {number} scrollDuration
     */
    scrollToTop(scrollDuration) {
        const cosParameter = window.scrollY / 2;
        let scrollCount = 0;
        let oldTimestamp = performance.now();

        /**
         * Stepping
         * @param {number} newTimestamp
         */
        function step(newTimestamp) {
            scrollCount += Math.PI / (scrollDuration /
                (newTimestamp - oldTimestamp));
            if (scrollCount >= Math.PI) window.scrollTo(0, 0);
            if (window.scrollY === 0) return;
            window.scrollTo(0, Math.round(cosParameter +
                cosParameter * Math.cos(scrollCount)));
            oldTimestamp = newTimestamp;
            window.requestAnimationFrame(step);
        }

        window.requestAnimationFrame(step);
    }

    /**
     * To top button listener
     * @param {MouseEvent} event
     */
    btnToTopListener(event) {
        event.preventDefault();
        this.scrollToTop(500);
    }
}

window.addEventListener('DOMContentLoaded', function(event) {
    new JNSDBR();
});

// Firefox history fix
window.onunload = function() {};
